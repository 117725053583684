import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ACTIVITY,
    DELETE_ACTIVITY,
    ACTIVITY_FORM_TOOGLE_LOADING,
    SET_ACTIVITY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ACTIVITY,
} from "../constants";
import { formatActivitys } from "./settingsActionsUtils";

/* ACTIVITY LIST */
export const fetchActivitys = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.activitys.list)
            .then((response) => {
                const activitys = formatActivitys(response.data);
                dispatch({
                    type: SET_ACTIVITY_LIST,
                    payload: keyBy(activitys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ACTIVITYS
export const createActivity = (newActivity) => {
    return async (dispatch) => {
        dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.activitys.create, newActivity)
            .then((response) => {
                const activity = formatActivitys(response.data);
                dispatch({ type: ADD_NEW_ACTIVITY, payload: activity });
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Actividad creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateActivity = (updatedActivity) => {
    return async (dispatch) => {
        dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.activitys.edit}${updatedActivity && updatedActivity._id}`, updatedActivity)
            .then((response) => {
                const activity = formatActivitys(response.data);
                dispatch({ type: UPDATE_ACTIVITY, payload: activity });
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Actividad actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteActivitys = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.activitys.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ACTIVITY, payload: Ids });
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Actividad eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const uploadImage = (data) => {
    return async (dispatch) => {
        dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
        return await axios
            .post(`${API.activitys.upload}`, data)
            .then((response) => {
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });

                return response;
            })
            .catch((err) => {
                dispatch({ type: ACTIVITY_FORM_TOOGLE_LOADING });
                return err;
            });
    };
};
