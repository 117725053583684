import { AimOutlined } from "@ant-design/icons";
import { CalendarOutlined, CreditCardOutlined, EnvironmentOutlined, GlobalOutlined, HeartOutlined, HomeOutlined, LikeOutlined, LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  travels: {
    key: "travels",
    slug: "travels",
    to: "/travels",
    title: "Viajes",
    icon: <GlobalOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  days: {
    key: "days",
    slug: "days",
    to: "/days",
    title: "Días",
    icon: <CalendarOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  activitys: {
    key: "activitys",
    slug: "activitys",
    to: "/activitys",
    title: "Actividads",
    icon: <EnvironmentOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  buys: {
    key: "buys",
    slug: "buys",
    to: "/buys",
    title: "Compras",
    icon: <CreditCardOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  coments: {
    key: "coments",
    slug: "coments",
    to: "/coments",
    title: "Comentarios",
    icon: <LikeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  likes: {
    key: "likes",
    slug: "likes",
    to: "/likes",
    title: "Me gustas",
    icon: <HeartOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  countrys: {
    key: "countrys",
    slug: "countrys",
    to: "/countrys",
    title: "Paises",
    icon: <AimOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },


  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

};
