import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  travels,
  travelsForm,
  days,
  daysForm,
  activitys,
  activitysForm,
  buys,
  buysForm,
  coments,
  comentsForm,
  likes,
  likesForm,
  countrys,
  countrysForm,
} from "./Reducers";

export default combineReducers({
  countrys,
  countrysForm,
  likes,
  likesForm,
  coments,
  comentsForm,
  buys,
  buysForm,
  activitys,
  activitysForm,
  days,
  daysForm,
  travels,
  travelsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
