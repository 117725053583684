/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* COUNTRY */
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const ADD_NEW_COUNTRY = "ADD_NEW_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const OPEN_COUNTRY_FORM = "OPEN_COUNTRY_FORM";
export const CLOSE_COUNTRY_FORM = "CLOSE_COUNTRY_FORM";
export const EDIT_SELECTED_COUNTRY = "EDIT_SELECTED_COUNTRY";
export const COUNTRY_FORM_TOOGLE_LOADING = "COUNTRY_FORM_TOOGLE_LOADING";
/* <---- COUNTRY ----> */

/* LIKE */
export const SET_LIKE_LIST = "SET_LIKE_LIST";
export const ADD_NEW_LIKE = "ADD_NEW_LIKE";
export const UPDATE_LIKE = "UPDATE_LIKE";
export const DELETE_LIKE = "DELETE_LIKE";
export const OPEN_LIKE_FORM = "OPEN_LIKE_FORM";
export const CLOSE_LIKE_FORM = "CLOSE_LIKE_FORM";
export const EDIT_SELECTED_LIKE = "EDIT_SELECTED_LIKE";
export const LIKE_FORM_TOOGLE_LOADING = "LIKE_FORM_TOOGLE_LOADING";
/* <---- LIKE ----> */

/* COMENT */
export const SET_COMENT_LIST = "SET_COMENT_LIST";
export const ADD_NEW_COMENT = "ADD_NEW_COMENT";
export const UPDATE_COMENT = "UPDATE_COMENT";
export const DELETE_COMENT = "DELETE_COMENT";
export const OPEN_COMENT_FORM = "OPEN_COMENT_FORM";
export const CLOSE_COMENT_FORM = "CLOSE_COMENT_FORM";
export const EDIT_SELECTED_COMENT = "EDIT_SELECTED_COMENT";
export const COMENT_FORM_TOOGLE_LOADING = "COMENT_FORM_TOOGLE_LOADING";
/* <---- COMENT ----> */

/* BUY */
export const SET_BUY_LIST = "SET_BUY_LIST";
export const ADD_NEW_BUY = "ADD_NEW_BUY";
export const UPDATE_BUY = "UPDATE_BUY";
export const DELETE_BUY = "DELETE_BUY";
export const OPEN_BUY_FORM = "OPEN_BUY_FORM";
export const CLOSE_BUY_FORM = "CLOSE_BUY_FORM";
export const EDIT_SELECTED_BUY = "EDIT_SELECTED_BUY";
export const BUY_FORM_TOOGLE_LOADING = "BUY_FORM_TOOGLE_LOADING";
/* <---- BUY ----> */

/* ACTIVITY */
export const SET_ACTIVITY_LIST = "SET_ACTIVITY_LIST";
export const ADD_NEW_ACTIVITY = "ADD_NEW_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const OPEN_ACTIVITY_FORM = "OPEN_ACTIVITY_FORM";
export const CLOSE_ACTIVITY_FORM = "CLOSE_ACTIVITY_FORM";
export const EDIT_SELECTED_ACTIVITY = "EDIT_SELECTED_ACTIVITY";
export const ACTIVITY_FORM_TOOGLE_LOADING = "ACTIVITY_FORM_TOOGLE_LOADING";
/* <---- ACTIVITY ----> */

/* DAY */
export const SET_DAY_LIST = "SET_DAY_LIST";
export const ADD_NEW_DAY = "ADD_NEW_DAY";
export const UPDATE_DAY = "UPDATE_DAY";
export const DELETE_DAY = "DELETE_DAY";
export const OPEN_DAY_FORM = "OPEN_DAY_FORM";
export const CLOSE_DAY_FORM = "CLOSE_DAY_FORM";
export const EDIT_SELECTED_DAY = "EDIT_SELECTED_DAY";
export const DAY_FORM_TOOGLE_LOADING = "DAY_FORM_TOOGLE_LOADING";
/* <---- DAY ----> */

/* TRAVEL */
export const SET_TRAVEL_LIST = "SET_TRAVEL_LIST";
export const ADD_NEW_TRAVEL = "ADD_NEW_TRAVEL";
export const UPDATE_TRAVEL = "UPDATE_TRAVEL";
export const DELETE_TRAVEL = "DELETE_TRAVEL";
export const OPEN_TRAVEL_FORM = "OPEN_TRAVEL_FORM";
export const CLOSE_TRAVEL_FORM = "CLOSE_TRAVEL_FORM";
export const EDIT_SELECTED_TRAVEL = "EDIT_SELECTED_TRAVEL";
export const TRAVEL_FORM_TOOGLE_LOADING = "TRAVEL_FORM_TOOGLE_LOADING";
/* <---- TRAVEL ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
