import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* TRAVELS */
export const getTravels = createSelector(
    (state) => state.travels,
    (travels) => travels
);
export const getTravelsForm = createSelector(
    (state) => state.travelsForm,
    (form) => form
);

/* DAYS */
export const getDays = createSelector(
    (state) => state.days,
    (days) => days
);
export const getDaysForm = createSelector(
    (state) => state.daysForm,
    (form) => form
);

/* ACTIVITYS */
export const getActivitys = createSelector(
    (state) => state.activitys,
    (activitys) => activitys
);
export const getActivitysForm = createSelector(
    (state) => state.activitysForm,
    (form) => form
);

/* BUYS */
export const getBuys = createSelector(
    (state) => state.buys,
    (buys) => buys
);
export const getBuysForm = createSelector(
    (state) => state.buysForm,
    (form) => form
);

/* COMENTS */
export const getComents = createSelector(
    (state) => state.coments,
    (coments) => coments
);
export const getComentsForm = createSelector(
    (state) => state.comentsForm,
    (form) => form
);

/* LIKES */
export const getLikes = createSelector(
    (state) => state.likes,
    (likes) => likes
);
export const getLikesForm = createSelector(
    (state) => state.likesForm,
    (form) => form
);

/* COUNTRYS */
export const getCountrys = createSelector(
    (state) => state.countrys,
    (countrys) => countrys
);
export const getCountrysForm = createSelector(
    (state) => state.countrysForm,
    (form) => form
);
